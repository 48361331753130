import React from "react"
import SEO from "../components/App/SEO"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"

const ElectricityWizard = () => {
  return (
    <Layout>
      <SEO
        title="Feature Unavailable | Oxygen"
        description="This feature is currently unavailable. Please contact us for more information."
      />
      <Navbar />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "80vh",
          textAlign: "center",
        }}
      >
        <h1>Feature Unavailable</h1>
        <p>
          This feature is not available now. Please contact us from our Contact
          Us section.
        </p>
      </div>
    </Layout>
  )
}

// const ElectricityWizard = () => {
//   return (
//     <Layout>
//       <SEO
//         title="Electricity and Gas Saver | Oxygen - Home Loans Made Simple"
//         description="At Oxygen we have a very simple value proposition. Provide our customers with a higher quality of advice, the most competitive loan, and do it hassle free."
//       />
//       <Navbar />
//       <div className="electricity-header-area">
//         <div className="container">
//           <div className="row">
//             <div className="col">
//               <div className="electricity-header-wrapper">
//                 <div className="wr-title-group">
//                   <PageBanner
//                     pageTitle="Compare your electricity bill in minutes and save an average of $94.37 a quarter*"
//                     pageSubTitle=""
//                   />
//                   <ReactWOW delay=".05s" animation="fadeInUp">
//                   <div className="btn-box">
//                     <Link
//                       target="_blank"
//                       to="https://compare.electricitywizard.com.au/about-you?campaign_id=118"
//                       className="default-btn primary-btn btn-lg"
//                     >
//                       Get Started <span></span>
//                     </Link>
//                   </div>
//                 </ReactWOW>
//                   </div>
//                 <img src={electricityscale} alt="electricity scale" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     {/* <div className="electricity-banner-area"> */}
//     <div className="container electricity-banner-area">
//       <div className="row">
//         <div className="col-md-4 d-flex justify-content-center wr-wizard-banner">
//           <img src={electricitybanner} alt="electricity banner"/>
//         </div>
//         <div className="col-md-8">
//           <div className="wr-content">
//             <p class="electricity-paragraph">
//               Did you know that the average Australian can save $94.37 a quarter on their electricity bill, or $75.55 a quarter on their gas bill*?
//             </p>
//             <p class="electricity-paragraph">
//               In fact, in the last 12 months, we’ve helped Australian’s save over $1M* on their energy bills and we’ve teamed up with Electricity Wizard to help members access these savings.
//             </p>
//             <small>
//               *Based on 13,326 unique comparisons completed by Electricity Wizard for the financial year 2020/2021 with 91 days & 90 days as the median billing days for electricity & gas respectively.
//             </small>
//           </div>
//           </div>
//       </div>
//     </div>
//     {/* </div> */}

//       <CTABanner />
//       <Footer />
//     </Layout>
//   )
// }

export default ElectricityWizard
